<template>
  <div class="covid-home">
    <div class="covid-home__container">
      <div class="covid-home__left">
        <div class="covid-text">
          <div class="covid-text__top">
            <div class="img-container">
              <img src="@/assets/img/covid.svg" alt="" />
            </div>
            <div class="title-container">
              <div class="num">{{dash.total_vacunados}}</div>
              <div class="title">
                Usuarios <br />
                inmunizados
              </div>
            </div>
          </div>
          <div class="covid-text__bottom">
            Folovid es una robusta herramienta de control sanitario. Te permite
            agilizar procesos, conocer el panorama general de empresa, hacer
            seguimiento y llevar completo registro.
          </div>
        </div>
      </div>
      <div class="covid-home__container-right" :class="!vacunados?'no-data':''">
        <div class="chart" :class="!vacunados?'no-data':''">
          <div class="chart-left" :class="!vacunados?'no':''">

            <apexchart v-if="vacunados" :options="chartOptions" :series="series"></apexchart>
            <div v-if="vacunados" class="chart-title">Estadísticas tipo de vacuna</div>
            <div v-if="!vacunados" class="chart-no-vacunas" >
            No existen registros de vacunas
            </div>
          </div>
        </div>
      </div>
    </div>
    <Status
      v-if="open_modal_status"
      :msg="modal_status_msg"
      :status="modal_status"
      @close="closeStatus"
    />
    <Spinner v-if="show_spinner" />
  </div>
</template>

<script>
import Spinner from "../Spinner.vue";
import Status from "../Modales/Status.vue";
import { DASHBOARD_VACUNAS } from "@/apollo/queries";

export default {
  components: { Status, Spinner },
  data() {
    return {
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      date_inicio: "Inicio",
      date_termino: "Término",
      key_calendar_inicio: 0,
      key_calendar_termino: 0,
      calendar_inicio: new Date(),
      calendar_termino: new Date(),
      show_calendar: null,
      max_date: new Date(),
      min_date: "",
      valores: [
        {
          id: 0,
          value: 6,
          title: "Personas contagiadas",
          subtitle: "",
        },
        {
          id: 1,
          value: 4,
          title: "Cuarentena",
          subtitle: "Cuarentena Comunal y cordón sanitario",
        },
        {
          id: 2,
          value: 1,
          title: "Cuarentena",
          subtitle: "Preventiva de salud",
        },
        {
          id: 3,
          value: 3,
          title: "Personas con teletrabajo",
          subtitle: "",
        },
        {
          id: 4,
          value: 6,
          title: "Cuarentena",
          subtitle: "Folovid",
        },
      ],
      chart_list: [
        "Pfizer",
        "Astrazeneca",
        "Cansino",
        "Sinovac",
        "Janssen",
        "Sputnik-V",
      ],
      edit_values: false,
      open_modal_status: false,
      modal_status_msg: "",
      modal_status: true,
      vacunados:true,
      series: [423, 545, 641, 234, 456, 786],
      chartOptions: {
        labels: [
          "Pfizer",
          "Astrazeneca",
          "Cansino",
          "Sinovac",
          "Janssen",
          "Sputnik-V",
        ],
        chart: {
          type: "donut",
          fontFamily: "Product Sans Regular",
          height: '100%',
          dropShadow: {
            enabled: false,
          },
        },
        dataLabels: {
          style: {
            colors: ["#fff"],
            fontSize: "16px",
            fontWeight: "bold",
          },
          dropShadow: {
            enabled: false,
          },
        },
        colors: [
          "#0072f7",
          "#7E88FD",
          "#B4B4FF",
          "#CFCFF5",
          "#D7D7EE",
          "#E8E9F3",
        ],
        legend: {
          show: true,
          fontSize: "16px",
          horizontalAlign: "center",
          markers: {
            width: 13,
            height: 13,
            strokeColor: "#fff",
            radius: 12,
          },
        },
        stroke: {
          show: false,
        },
        plotOptions: {
          pie: {
            startAngle: 0,
            endAngle: 360,
            customScale: 1,
            dataLabels: {
              offset: 0,
              minAngleToShowLabel: 10,
            },
            donut: {
              size: "50%",
              background: "transparent",
            },
          },
        },
      },
      dash:{},
      show_spinner:false
    };
  },
  mounted(){
    this.getDash();
  },
  methods: {
   async getDash() {
    this.show_spinner = true;
      this.users = [];
      var vars = {
        id_empresa: this.id_empresa,
      };
    await this.$apollo
        .query({
          query: DASHBOARD_VACUNAS,
          variables: vars,
        })
        .then((res) => {
          this.show_spinner = false;
          var resp = res.data.DashboardVacunas;
          this.dash = resp;
          if(resp.total_vacunados == "0"){
            this.vacunados = false;
          }
          var element_name =[];
          var element_data =[];
          for (let i = 0; i < resp.estadisticas_vacunas.length; i++) {
            element_name.push(resp.estadisticas_vacunas[i].nombre);
            element_data.push(Number(resp.estadisticas_vacunas[i].total_vacunas));
          }
          this.chartOptions.labels = element_name;
          this.series = element_data;
        })
        .catch((err) => {
          this.show_spinner = false;
          console.log("err", err);
        });
    },
    openCalendar(cal) {
      if (this.show_calendar == cal) {
        this.show_calendar = null;
      } else {
        this.show_calendar = cal;
      }
    },
    onDayClick(day) {
      if (this.show_calendar == "inicio") {
        this.date_inicio = day.id;
      }
      if (this.show_calendar == "termino") {
        this.date_termino = day.id;
      }

      setTimeout(() => {
        this.show_calendar = null;
      }, 500);
    },
    // inputChange(id, val) {
    //   var value = Number(val);
    //   console.log(id, value);
    // },
    activeEditValues() {
      this.edit_values = true;
    },
    saveEditedValues() {
      this.edit_values = false;
      this.open_modal_status = true;
      this.modal_status_msg = "Valores editados con exito";
      this.edit_values = false;
    },
    closeStatus() {
      this.open_modal_status = false;
    },
  },
};
</script>
